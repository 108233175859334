import api from "@/api"
import { commitT, TableRow } from "@/models/type"
import { UserTeamRow } from "@/store/modules/user"
import { isEmail } from "@/utils/validate"
import { ElMessageBox, ElNotification } from "element-plus"
import { ElFormItemContext } from "element-plus/lib/el-form"
import { Options, Vue } from "vue-class-component"
import { mapGetters } from "vuex"
import './index.scss'


@Options({
  computed: {
    ...mapGetters(['GetUserTeam'])
  }
})
export default class MineTeam extends Vue {
  public GetUserTeam?: UserTeamRow // 团队信息
  tableData: any[] = []
  ids: number[] = []
  tableList: TableRow[] = [
    { label: '用户名', prop: 'username' },
    { label: '中文', prop: 'nickname' },
    { label: '英文', prop: 'enName' },
    { label: '职位', prop: 'job' },
    { label: '身份证', prop: 'cardId' },
    { label: '手机号', prop: 'phone' },
    { label: '邮箱', prop: 'email' },
  ]

  public emailValidate = (rules: any, value: string, cb: commitT): void => {
    if(!value) {
      cb(new Error('请输入邮箱'))
    } else if (!isEmail(value)) {
      cb(new Error('请输入正确格式邮箱'))
    } else {
      cb()
    }
  }

  show = false
  submitForm: TeamMemberParam = {
    cardId: '',
    email: '',
    enName: '',
    job: '',
    nickname: '',
    password: '',
    phone: '',
    teamId: 0,
    username: '',
  }
  formRules :any = {
    username: [
      { required: true, message: '请输入用户名' }
    ],
    cardId: [
      { required: true, message: '请输入身份证号' }
    ],
    email: [
      { validator: this.emailValidate, required: true }
    ],
    phone: [
      { required: true, message: '请输入手机号' }
    ],
    password: [
      { required: true, message: '请输入密码' }
    ]
  }

  onSelectionChange(row: any): void {
    this.ids = row.map((item: any) => item.id)
  }

  showClose(): void {
    this.show = false
    this.submitForm = {
      cardId: '',
      email: '',
      enName: '',
      id: 0,
      job: '',
      nickname: '',
      password: '',
      phone: '',
      teamId: 0,
      username: '',
    }
  }

  dialogDom(): JSX.Element {

    return <el-dialog
      title="创建账户"
      width="450px"
      v-model={this.show}
      customClass="team-dialog"
      beforeClose={this.showClose}
    >
      <el-form
        ref="form"
        model={this.submitForm}
        rules={this.formRules}
        label-width="100px"
      >
        <el-form-item label="用户名" prop="username">
          <el-input v-model={this.submitForm.username} placeholder="请输入用户名" clearable/>
        </el-form-item>
        <el-form-item label="中文名" prop="nickname">
          <el-input v-model={this.submitForm.nickname} placeholder="请输入中文名" clearable/>
        </el-form-item>
        <el-form-item label="英文名" prop="enName">
          <el-input v-model={this.submitForm.enName} placeholder="请输入英文名" clearable/>
        </el-form-item>
        <el-form-item label="职位" prop="job">
          <el-input v-model={this.submitForm.job} placeholder="请输入职位" clearable/>
        </el-form-item>
        <el-form-item label="身份证" prop="cardId">
          <el-input v-model={this.submitForm.cardId} placeholder="请输入身份证" clearable/>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model={this.submitForm.phone} placeholder="请输入手机号" clearable/>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model={this.submitForm.email} placeholder="请输入邮箱" clearable/>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model={this.submitForm.password} placeholder="请输入密码" clearable/>
        </el-form-item>
      </el-form>
      <div class="submit" onClick={this.submit}>完成创建</div>
    </el-dialog>
  }

  submit(): void {
    const __form = this.$refs.form as ElFormItemContext
    __form.validate(v => {
      if(v) {
        const obj = Object.assign({}, this.submitForm)
        obj.teamId = this.GetUserTeam?.id as number
        api.teamAddMember(obj).then(res => {
          ElNotification({
            type: 'success',
            title: '成功',
            message: '创建成功'
          })
          this.$store.dispatch('getUserInfo')
          this.showClose()
          this.getList()
        })
      } else {
        return false
      }
    })
  }

  getList(): void {
    api.getMemberInfoByTeamId({ id: this.GetUserTeam?.id as number }).then(res => {
      this.tableData = res.data
    })
  }

  teamAdd(): void {
    this.show = true
  }
  teamDelete(): void {
    if(!(this.ids.length)) {
      ElNotification({
        type: 'warning',
        message: '请选择要删除的账户列表'
      })
      return
    }
    ElMessageBox.confirm('确定删除该账户？', '删除账户', {
      type: 'warning'
    }).then(() => {
      api.teamDelMember(this.ids).then(res => {
        ElNotification({
          type: 'success',
          message: '删除成功'
        })
        this.$store.dispatch('getUserInfo')
        this.getList()
      })
    }).catch(() => false)
  }

  render(): JSX.Element {
    return <div class="team">
      <div class="team-header">
        <div onClick={this.teamAdd}>创建账户</div>
        <div onClick={this.teamDelete}>删除账户</div>
      </div>
      <div class="account">账号使用：{this.GetUserTeam?.teamCount}/{this.GetUserTeam?.accountNumber}</div>
      <el-table
        data={this.tableData}
        align="center"
        style={{
          width: '100%'
        }}
        onSelectionChange={this.onSelectionChange}
      >
        <el-table-column type="selection"/>
        {this.tableList.map(item => <el-table-column
          label={item.label}
          align='center'
          v-slots={item.slot}
          prop={item.prop}
        />)}
      </el-table>
      {this.show && this.dialogDom()}
    </div>
  }
  mounted(): void {
    setTimeout(() => {
      this.getList()
    }, 1000)
  }
}

export interface TeamMemberParam {
  cardId?: string
	email?: string
	enName?: string
	id?: number
	job?: string
	nickname?: string
	password?: string
	phone?: string
	teamId?: number
	username?: string
}
